import { createContext, ReactNode, useEffect, useReducer } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'redux/store';
// import useWebSocket from '../components/common/websocket';

// utils
// import axiosInstance from '../utils/axios';

import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';
import { settoinitailState } from '../redux/slices/attendance';
import { pageTitleRedux } from '../redux/slices/pageslice';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          try {
            const response = await axios.post('/master/LoginDetailsFromJWT/');
            if (response.status !== 200 || response.data.status === 'IN') {
              logout();
            }
            // const user = response.data;
            // const { user } = response.data;
            const user = response.data;
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user
              }
            });
          } catch (e) {
            setSession(null);
            logout();
          }
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        // console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  // const login = async (email: string, password: string) => {
  //   const response = await axios.post('/master/send-otp/', {
  //     email,
  //     password
  //   });
  //   return response;
  // };
  // const verifyOtp = async (email: string, password: string, otp: any) => {
  //   const response = await axios.post('/master/login/', {
  //     email,
  //     password,
  //     otp
  //   });
  //   // console.log('response', response);
  //   const accessToken = response.data.jwt_token;
  //   setSession(accessToken);
  //   const user = response.data;
  //   // console.log(user, 'useruseruser12');
  //   // console.log('accessToken', accessToken);
  //   // console.log('user', user);
  //   // const userData = await axios.post('/master/userDetails/');
  //   // const user = userData.data;
  //   // set user details here

  //   // for saving registerd company site wide
  //   const cresponse = await axios.get('/master/company-enroll/');
  //   const { data } = cresponse;
  //   if (data.length !== 0) {
  //     const CompanyID = cresponse.data[0].id;
  //     localStorage.setItem('CompanyID', CompanyID);
  //   }
  //   dispatch({
  //     type: Types.Login,
  //     payload: {
  //       user
  //     }
  //   });
  // };
  const login = async (email: string, password: string) => {
    const response = await axios.post('/master/login/', {
      email,
      password
    });
    // console.log('response', response);
    const accessToken = response.data.jwt_token;
    setSession(accessToken);
    const user = response.data;
    // console.log(user, 'useruseruser12');
    // console.log('accessToken', accessToken);
    // console.log('user', user);
    // const userData = await axios.post('/master/userDetails/');
    // const user = userData.data;
    // set user details here

    // for saving registerd company site wide
    const cresponse = await axios.get('/master/company-enroll/');
    const { data } = cresponse;
    if (data.length !== 0) {
      const CompanyID = cresponse.data[0].id;
      localStorage.setItem('CompanyID', CompanyID);
    }
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };
  const dispatch2 = useDispatch();
  // const websocket = useWebSocket();

  const logout = async () => {
    try {
      const logoutcheck = await axios.post('/master/logout/');
      if (logoutcheck.data.message === 'success') {
        setSession(null);
        localStorage.removeItem('CompanyID');
        dispatch({ type: Types.Logout });
        dispatch2(settoinitailState());
        dispatch2(pageTitleRedux('Attendance'));
        navigate(0);
      }
    } catch (err) {
      navigate(0);
    }
  };

  const resetPassword = async (email: string) => {
    const response = await axios.post('/master/forget-password/', {
      email
    });
    return response;
  };

  // const resetPassword = (email: string) => console.log(email);

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        // verifyOtp,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
